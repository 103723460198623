import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Meetings.scss";
import { baseURL } from "../../BaseUrl";
import { exportExcel } from "../../DownloadFile";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import AccessDenied from "../../../AccessDenied";
import { Col, Image, Row } from "react-bootstrap";
import mobileimg from "../../../images/iphone.svg";
import { useNavigate } from "react-router-dom";
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";
import { MiClearButton } from "../../SbButtons/SbCancelBtn";
import { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import Footer from "../../Footer";
import { travelizeAuth } from "../../HeaderAuthentication";
import DownloadLoading from "../../ReUseComponents/DownloadLoading";
import { loginSubscriptionID } from "../../LoginRole";

function DeviceDetails() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [deviceDetails, setDeviceDetails] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [subType, setSubType] = useState(loginSubscriptionID);
  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [companyName, setCompanyName] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadIsLoading, setDownloadIsLoading] = useState(false);

  // Lazy Loading
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // PAGINATION END

  useEffect(() => {
    window.scrollTo(0, 0);
    {
      subType && fetchDropdownData();
    }
  }, [subType]);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subscription/BindUsersDropDown?SubscriptionId=${subType}`,
      requestOptionZone
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
  };

  useEffect(() => {
    fetchDropdowns();
  }, []);

  const fetchDropdowns = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Subscription/BindCompanyDropdown?SubscriptionTypeID=0`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setCompanyList(
            res.result.map((data) => {
              return {
                value: data.subscriptionID,
                label: data.companyName,
              };
            })
          );
        } else {
          setCompanyList([]);
        }
      });
  };

  const [subscriptionVal, setSubscriptionVal] = useState({
    value: loginSubscriptionID,
    label: "",
  });

  useEffect(() => {
    if (subType !== "" && subType !== null) {
      companyList.map((data) => {
        if (data.value == subType) {
          setSubscriptionVal(data);
        }
      });
    }
  }, [subType, companyList]);

  // GET SUBSCRIPTION PAGE START
  useEffect(() => {
    {
      subType && fetchDeviceDetails();
    }
  }, [PageSize, currentPage, subType, filterUserId, companyName]);

  const fetchDeviceDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/SupportTeam/GetAllDevices?SubscriptionId=${subType}&UserId=${filterUserId}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDeviceDetails(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setDeviceDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      });
  };
  // GET SUBSCRIPTION PAGE END

  const clearSerachBySelect = () => {
    setSubType(loginSubscriptionID);
    setFilterUserId("");
  };

  const exportExcelDeviceDetails = () => {
    exportExcel(
      `${baseURL}/api/Export/DeviceExcelExport?SubscriptionId=${companyName}&UserId=${filterUserId}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "DeviceDetails.xlsx",
      setDownloadIsLoading
    );
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // Page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={6} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}>
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6 className="page-header-text">DEVICE DETAILS</h6>
                      </div>
                    </Col>
                    <Col md={6} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={companyList}
                              placeholder="Select Company"
                              value={subscriptionVal}
                              onInputChange={companyList.label}
                              onChange={(data) => setSubType(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <div>
                            <Select
                              className="react-select-container-list"
                              classNamePrefix="react-select-list"
                              options={userList}
                              placeholder="Select User"
                              value={filterUserId === "" ? "" : userList.value}
                              onInputChange={userList.label}
                              onChange={(data) => setFilterUserId(data.value)}
                              isSearchable={true}
                            />
                          </div>
                          <MiClearButton
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* Filter and Header Section */}
                {/* USER TABLE START */}
                <div className="meetings-table">
                  <div
                    className="table-responsive meetings-scrollbar"
                    onScroll={(e) =>
                      handleScroll(
                        e,
                        deviceDetails,
                        totalData,
                        setLoading,
                        setPageSize,
                        PageSize
                      )
                    }
                  >
                    <table className="table meetings-list-table">
                      <thead className="thead-light meetings-list-thead">
                        <tr>
                          <th>USER NAME</th>
                          <th>DEVICE INFO</th>
                          <th>BATTERY</th>
                          <th>STORAGE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deviceDetails !== "" ? (
                          deviceDetails?.map((data, i) => (
                            <tr className="meeting-list-row-data" key={i}>
                              <td>
                                <p className="d-flex">
                                  <Image
                                    src={mobileimg}
                                    alt="Mobile-device"
                                    className="mobile-img"
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                    }}
                                  />
                                  <p>
                                    <b>{data?.userFullName}</b>
                                    <br />
                                    {moment(data?.date).format("DD/MM/YYYY")}
                                  </p>
                                </p>
                              </td>
                              <td>
                                <p>
                                  <b>Name : </b>
                                  {data?.name}
                                </p>
                                <p>
                                  <b>Modal : </b> {data?.modal ?? "NA"}
                                </p>
                                <p>
                                  <b>OS : </b> {data?.os}
                                </p>
                                <p>
                                  <b>MI-Version : </b> {data?.osVersion}
                                </p>
                              </td>
                              <td>
                                <b>Tech : </b> {data?.batteryTech} <br />
                                <b>Health : </b> {data?.batteryHealth}
                                <br />
                                <b>Power : </b> {data?.batteryPower} <br />
                              </td>
                              <td>
                                <b>RAM : </b> {parseFloat(data?.ram).toFixed(2)}
                                <br />
                                <b>Rooted : </b>
                                {data?.rooted}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <h6 className="no-data-found">
                            ------ NO DATA FOUND ------
                          </h6>
                        )}
                        {loading && <OnScrollLoading />}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <SbDownloadBtn onClickEffect={exportExcelDeviceDetails} />
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
          <Footer />
          {downloadIsLoading && (
            <>
              <DownloadLoading />
            </>
          )}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default DeviceDetails;
